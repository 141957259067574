@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'chollasans-regular';
    src: url('../public/fonts/chollasans-regular/chollasans-regular-webfont.woff2') format('woff2'),
        url('../public/fonts/chollasans-regular/chollasans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'metropolis-regular';
    src: url('../public/fonts/metropolis-regular/metropolis-regular-webfont.woff2') format('woff2'),
        url('../public/fonts/metropolis-regular/metropolis-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.chollasans {
    font-family: 'chollasans-regular', Arial, Helvetica, sans-serif;
}

.metropolis {
    font-family: 'metropolis-regular', 'Times New Roman', Times, serif;
}


.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.iframe {
    overflow: hidden;
    border: 0;
    align-self: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bg-trapcat-blue {
    background-color: #00c0e4;
}

.bg-trapcat-yellow {
    background-color: #f0f700;
}

.bg-trapcat-gray {
    background-color: #8a8a8a;
}

.bg-trapcat-black {
    background-color: #000000;
}

.text-trapcat-blue {
    color: #00c0e4;
}

.text-trapcat-yellow {
    color: #f0f700;
}

.text-trapcat-gray {
    color: #8a8a8a;
}

.text-trapcat-black {
    color: #000000;
}

/*
body {
    background-image: url('../public/img/cat-playing.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    animation: bounce 100s linear infinite;
}
*/
/*
#bg-video {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    object-fit: cover;
    top:40px;
    left:0;
}




@keyframes bounce {
    0% {
        background-size: 100% auto;
    }

    50% {
        background-size: 120% auto;
    }

    100% {
        background-size: 100% auto;
    }
}


.hero {
    background-image: url('../public/img/pattern.png');
    background-repeat: repeat;
    background-size: 10%;
    animation: hero-anim 100s linear infinite;
}


@keyframes hero-anim {
    100% {
        background-position: 0 3000px;
    }
}

.menu-toggle {
    position: relative;
    background-color: transparent;
    z-index: 2;
    border: none;
    width: 30px;
    height: 25px;
    outline: none;
    transition: opacity 0.2s ease-out;
}

.menu-toggle:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    right: auto;
    width: 100%;
    background: linear-gradient(to bottom, #fff, #fff 20%, transparent 20%, transparent 40%, #fff 40%, #fff 60%, transparent 60%, transparent 80%, #fff 80%, #fff 100%);
    transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out;
}

.menu-toggle:after {
    opacity: 0;
    content: "×";
    color: black;
    position: absolute;
    top: 16px;
    left: -4px;
    font-family: Arial, sans-serif;
    font-size: 76px;
    line-height: 0;
    transition: opacity 0.4s ease-out;
}

.menu-toggle:active {
    transform: translateY(2px);
}

.menu-toggle:hover {
    opacity: 1;
}

.open .menu-toggle {
    opacity: 1;
}

.open .menu-toggle:before {
    opacity: 0;
    width: 0;
}

.open .menu-toggle:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(360deg);
    transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out;
}

nav {
    z-index: 1;
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
}

nav:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(240, 247, 0, 0.9);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    transform: scale(0.04), translateY(9999px);
    overflow: hidden;
}

.open nav {
    top: 0;
}

.open nav:before {
    -webkit-animation: menu-animation 0.8s ease-out forwards;
    animation: menu-animation 0.8s ease-out forwards;
}

ul.menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000;
    color: black;
}

ul.menu li {
    opacity: 0;
    text-align: center;
    transform: translate3d(0, 36px, 0);
}

ul.menu li a {
    font-family: 'chollasans-regular';
    font-size: 2.5em;
    padding: 10px;
}


ul.menu li a:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    left: auto;
    background-color: black;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: width 0.14s ease-out;
}

ul.menu li a:after {
    opacity: 0;
    content: attr(data-text);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    color: #f0f700;
    overflow: hidden;
    transform: translate(-24px, 6px);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
}

ul.menu li a:hover:before {
    left: 0;
    right: auto;
    width: 100%;
}

ul.menu li a:hover:after {
    opacity: 1;
    transform: translate(0px, 6px);
    transition: transform 0.2s 0.14s ease-out, opacity 0.2s 0.14s ease-out;
}

.open ul.menu li {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.open ul.menu li:nth-child(1) {
    transition-delay: 0.75s;
}

.open ul.menu li:nth-child(2) {
    transition-delay: 0.85s;
}

.open ul.menu li:nth-child(3) {
    transition-delay: 0.95s;
}

.open ul.menu li:nth-child(4) {
    transition-delay: 1.05s;
}

@-webkit-keyframes menu-animation {
    0% {
        opacity: 0;
        transform: scale(0.04) translateY(300%);
    }

    40% {
        transform: scale(0.04) translateY(0);
        transition: ease-out;
    }

    40% {
        transform: scale(0.04) translateY(0);
    }

    60% {
        opacity: 1;
        transform: scale(0.02) translateY(0px);
    }

    61% {
        transform: scale(0.04);
    }

    99.9% {
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%;
    }

    100% {
        transform: scale(2);
        height: 100%;
        padding-bottom: 0;
        border-radius: 0;
    }
}

@keyframes menu-animation {
    0% {
        opacity: 0;
        transform: scale(0.04) translateY(300%);
    }

    40% {
        transform: scale(0.04) translateY(0);
        transition: ease-out;
    }

    40% {
        transform: scale(0.04) translateY(0);
    }

    60% {
        opacity: 1;
        transform: scale(0.02) translateY(0px);
    }

    61% {
        transform: scale(0.04);
    }

    99.9% {
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%;
    }

    100% {
        transform: scale(2);
        height: 100%;
        padding-bottom: 0;
        border-radius: 0;
    }
}
*/